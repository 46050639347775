import { Accordion, Button, Table } from "react-bootstrap";
import { regis } from "../../App";

var questions = [
  "¿Qué son las líneas de apuestas y dónde ver?",
  "¿Cómo retirar dinero de Codere México?",
  "¿Qué son las apuestas deportivas?",
  "¿Cómo funcionan los momios americanos?",
  "¿Cómo funcionan las apuestas deportivas?",
  "¿Cómo apostar en línea?",
];
var answers = [
  "Las líneas de apuestas se determinan por un valor numérico para establecer la ventaja de un equipo con goles, puntos, carreras, córners, etc; y éstas se pueden identificar mediante los signos (+) o (-), además le sirven al jugador para tener referencia respecto a los diferentes mercados de apuestas de un evento deportivo determinado. Las puedes encontrar en la sección de Deportes y Deportes en vivo.",
  "Primer debes hacer una verificación de tu cuenta y después elegir el método de retiro. Recuerda que tenemos las opciones de transferencia bancaria, cobro en efectivo en de nuestras sucursales, así como en Oxxo, entre otras. Para cualquier problema que tengas en este proceso puedes comunicarte a nuestro contacto: +52 56 2573 2721",
  "Es la opción de apostar una cantidad de dinero a un resultado probable que pueda resultar de un encuentro deportivo de cualquier disciplina.",
  "Cuando el momio es positivo, indica la cantidad que podrías ganar si apuestas 100 pesos, por ejemplo; si apuesta $100 con momio de +450, ganarías $550. Por otro lado, si el momio es negativo, indica la cantidad que debes apostar para obtener una ganancia de 100 pesos.",
  `Las apuestas deportivas ofrecen una variedad de opciones de cuotas o momios, desde el resultado final de un partido hasta detalles específicos como el número de goles o tiros de esquina. Los "momios", determinados por analistas o traders, reflejan las probabilidades de que un suceso ocurra y las ganancias potenciales al realizar una apuesta, y sitios como Codere proporcionan un entorno seguro para realizar apuestas, respaldado por el permiso de SEGOB.`,
  "Entra a Codere.mx la casa de apuestas de los GOATS, donde podrás apostar en cualquier evento deportivo a través del sitio web o aplicación móvil para Android y iOS, asegurando un proceso seguro. Nuestras plataformas ofrecen la visualización de estimaciones de ingresos, la posibilidad de cerrar apuestas antes del final del evento y la oportunidad de explorar gran variedad de combinaciones de apuestas, además de aprovechar promociones como apuestas gratis, devoluciones de efectivo y ofertas especiales",
];

function Content() {
  // const prefix = "https://apuestas.codere.mx/es_MX/";

  return (
    <div className="content-container container">
      <h1 className="text-center">
        La Casa Líder de Apuestas Deportivas en México
      </h1>
      <p>
        Codere.mx te da la bienvenida a una nueva forma de disfrutar tus
        deportes favoritos. Somos expertos en apuestas deportivas en línea y
        ofrecemos una experiencia única con una amplia variedad de mercados y
        eventos destacados como el futbol mexicano, el futbol internacional y
        deportes americanos como basquetbol, futbol americano y beisbol. Vive la
        emoción con nuestras apuestas deportivas en vivo y aprovecha los mejores
        momios en cada partido.
      </p>

      <div className="row justify-content-center">
        <div className="col-xs-12 col-md-4">
          <h2>Seguridad y Confianza en Apuestas en Línea</h2>
          <p>
            En Codere, nuestra prioridad es brindarte un entorno seguro,
            confiable y transparente para tus apuestas online. Implementamos las
            más avanzadas tecnologías de protección para garantizar que tu
            información personal y financiera esté siempre resguardada.
          </p>
          <p>
            Nos comprometemos a ofrecerte una experiencia de juego responsable,
            donde puedas disfrutar al máximo con la tranquilidad de que tus
            apuestas están respaldadas por prácticas seguras y reguladas.
          </p>
          <div>
            <h2>Variedad de Opciones en Apuestas Deportivas </h2>
            <p>
              Codere te ofrece múltiples tipos de apuestas adaptadas a tus
              preferencias, desde apuestas en vivo hasta opciones avanzadas como
              hándicap asiático o marcan ambos equipos. Ya sea que quieras
              apostar en futbol o cualquier otro deporte, encontrarás
              estadísticas detalladas para tomar las mejores decisiones.
            </p>
            <ul>
              <li>
                <u>Apuestas 1X2</u>: Es la opción más tradicional. Por ejemplo,
                en un partido entre Águilas y Guadalajara, puedes apostar al
                triunfo del local (1), al empate (X) o a la victoria del
                visitante (2).
              </li>
              <li>
                <u>Doble oportunidad</u>: Aumenta tus posibilidades combinando
                resultados. Por ejemplo, en un juego entre La Máquina y
                Universidad, puedes apostar a que el local gana o empata (1X), o
                incluso a que cualquiera de los dos equipos gane (12).
              </li>
            </ul>
          </div>
        </div>

        <div className="col-xs-12 col-md-4">
          <ul>
            <li>
              <u>Marcador exacto</u>: Predice el resultado exacto del partido.
              Por ejemplo, en un encuentro entre México y Argentina, puedes
              apostar a un marcador de 2-1 a favor de México. El momio para
              estas apuestas son de gran valor, debido a lo difícil que puede
              ser acertar al marcador, por lo que es un buen reto para todos los
              jugadores.
            </li>
            <li>
              <u>Hándicap y hándicap asiático</u>: Agrega un desafío extra a tus
              apuestas. Por ejemplo, si el Real Madrid tiene un hándicap de -1,
              deben ganar por al menos dos goles para que tu jugada sea
              ganadora. En el hándicap asiático, si el resultado es exactamente
              el margen elegido, la apuesta se anula y recuperas tu dinero, lo
              que resulta más seguro para algunos jugadores.
            </li>
            <li>
              <u>Primer equipo en marcar</u>: Una apuesta sencilla pero
              emocionante. Por ejemplo, en un juego de la NFL entre Dallas y
              Philadelphia, puedes apostar a quién logrará el primer touchdown.
            </li>
            <li>
              <u>Apuestas combinadas</u>: Multiplica la emoción combinando
              diferentes mercados. Por ejemplo, en un partido del futbol
              mexicano, puedes apostar a que el partido terminará en empate y
              que ambos equipos marcarán (X + ambos anotan).
            </li>
            <li>
              <u>Marcan ambos equipos</u>: Ideal para partidos con equipos
              ofensivos. Por ejemplo, en un enfrentamiento entre Barcelona y
              Bayern Múnich, puedes apostar a que ambos equipos anotarán al
              menos un gol durante el partido.
            </li>
          </ul>
          <p>
            Explora todas las opciones y haz que tus apuestas deportivas en
            México sean emocionantes y rentables.
          </p>
        </div>

        <div className="col-xs-12 col-md-4">
          <aside>
            <h2>Más que Apuestas: Vive el Casino Online de México </h2>
            <p>
              <strong>Codere</strong> no solo lidera las apuestas deportivas en
              línea, también redefine la experiencia del casino online en México
              con su oferta única y de alta calidad.
            </p>
            <h3>¿Qué te espera al jugar Online?</h3>
            <p>
              Disfruta de una experiencia de juego diseñada para emocionarte y
              entretenerte al máximo:
            </p>
            <ul>
              <li>
                <a href="https://www.codere.mx/library/ruleta.html">
                  <u>Ruleta online</u>
                </a>
                : La elegancia y emoción de este clásico desde cualquier lugar.
              </li>
              <li>
                <a href="https://www.codere.mx/yak">
                  <u>Slots clásicos</u>
                </a>
                : Disfruta de las máquinas tragamonedas o slots más populares y
                sus increíbles premios.
              </li>
              <li>
                <a href="https://www.codere.mx/library/blackjack.html">
                  <u>Mesas en vivo de blackjack</u>
                </a>
                : Juega con crupieres reales y siente la adrenalina de un casino
                físico.
              </li>
              <li>
                <a href="https://www.codere.mx/casino-en-vivo">
                  <u>Casino Live</u>
                </a>
                : Vive una experiencia inmersiva y en vivo con gráficos de
                última generación.
              </li>
              <li>
                <a href="https://www.codere.mx/casino">
                  <u>Casino Online</u>
                </a>
                : Tecnología avanzada que te transporta a un entorno realista y
                envolvente.
              </li>
            </ul>
            <p>
              Prepárate para descubrir un universo de entretenimiento
              inigualable, diseñado para que vivas cada jugada al máximo. ¡La
              diversión comienza aquí!
            </p>
          </aside>
          <div>
            <h2>Aprovecha tu Bono de Bienvenida </h2>
            <p>
              En Codere.mx, cada jugada es una invitación a experimentar la
              emoción del juego con seguridad y confianza. ¡Aprovecha nuestro
              bono de bienvenida de hasta 5,000 para comenzar tu aventura! Únete
              hoy y vive la pasión de las apuestas en línea en México.{" "}
            </p>
          </div>
        </div>
      </div>
      <Table striped bordered hover variant="dark" className="mb-4 w-75">
        <tbody>
          <tr>
            <td>🏆 Inicio de operaciones</td>
            <td>2019</td>
          </tr>
          <tr>
            <td>⚽ Apuestas Deportivas </td>
            <td>Apuestas futbol, Apuestas Superbowl, Apuestas NBA y más</td>
          </tr>
          <tr>
            <td>🎲 Licencia</td>
            <td>
              Oficio número 2768, DGG/SP/442/1997, DGJS/DGAAD/DCRCA/1566/2018,
              DGJS/1018/2015, DGJS/234/2019 y DGJS/1427/2023
            </td>
          </tr>
          <tr>
            <td>✔️ Moneda</td>
            <td>Peso Mexicano</td>
          </tr>
          <tr>
            <td>💰 Depósito mínimo</td>
            <td>$100 MXN</td>
          </tr>
          <tr>
            <td>💸 Retiro</td>
            <td> Mínimo $10 MNX (Diez Pesos 00/100 M.N.)</td>
          </tr>
          <tr>
            <td>💳 Formas de Pago</td>
            <td>
              Visa, Paynet, MasterCard, Transferencia Bancaria, Teleingreso,
              Oxxo, SPEI
            </td>
          </tr>
        </tbody>
      </Table>

      <section className="mt-5 w-75 m-auto faq">
        <h2>FAQ’S apuestas deportivas</h2>
        <Accordion className="content-accordion">
          {answers.map((answer, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header as="h3">{questions[index]}</Accordion.Header>
                <Accordion.Body>
                  <p>{answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </section>

      <div className="mt-4 text-center animate-sec">
        <div className="hr mb-2" />
        <span className="animate color-gr fw-bold">
          ¡ANÍMATE Y DESCUBRE TODAS LAS VENTAJAS QUE TE OFRECE CODERE!
        </span>
        <div className="hr mt-2" />
        <div className="mt-4">
          <span className="bono color-gr p-2 fw-bold">BONO DE BIENVENIDA</span>
          <div className="fw-bold fs-2 mt-3 hasta">
            DE HASTA $5,000 PARA JUGAR EN TUS PRIMEROS 3 DEPÓSITOS
          </div>
          <Button
            href={regis}
            className="registrate-button m-auto mt-3 fs-4 text-white"
          >
            ¡Regístrate ahora!
          </Button>
          <div className="hr mt-4" />
        </div>
      </div>
    </div>
  );
}

export default Content;
