import {
  Form,
  Button,
  NavLink,
  FloatingLabel,
  Modal,
  Spinner,
} from "react-bootstrap";
import "../../CSS/login.css";
import { useState, useEffect, useRef } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { regis } from "../../App";

export default function Login() {
  const [showModal, setShowModal] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);

  const [isHuman, setIsHuman] = useState(true);

  const captchaRef = useRef(null);
  const [valid_token, setValidToken] = useState([]);

  const pwRef = useRef(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [message, setMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [showEyeSlash, setShowEyeSlash] = useState(false);

  const alphanum_regex = /[^0-9a-z@. _-]/gi;

  const [username, setUserName] = useState("");
  const [pw, setPw] = useState("");

  var status;

  class UserEvent {
    constructor(event, userId, userName, status) {
      this.event = event;
      this.userId = userId;
      this.userName = userName;
      this.status = status;
    }
  }

  (async function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-TD8QNS7");

  const onSubmit = (evt) => {
    // console.log(isHuman);
    if (!isHuman) {
      setShowSpinner(true);

      // recapcha();
    } else login();
    evt.preventDefault();
  };

  const scriptLoaded = () => {
    // setPageLoaded(true);
    login();
  };

  const login = () => {
    setShowSpinner(true);
    try {
      // console.log("isHuman >>>");
      // console.log(isHuman);
      window.iapiLogin(username, pw, 1, "es-mx", 0);
    } catch (error) {
      // console.log(error);
      setMessage("Algo salió mal. Por favor contacte a su administrador");
      setShowSpinner(false);
    }

    try {
      window.iapiSetCallout(window.iapiCALLOUT_LOGIN, function (response) {
        var push = {};
        if (
          response.errorCode === 0 &&
          response.hasOwnProperty("sessionValidationData")
        ) {
          // console.log("has");
          setLoginResponse(response);
          setShowModal(true);
          return;
        }
        if (response.errorCode === 0) {
          status = "Success";
          push = {
            event: "Login",
            status: status,
            userId: response.username.userId,
            userName: response.username.username,
            playerCode: "",
            url: "/library/native/login/index.html",
            vipLevel: "",
          };
          setMessage("");

          window.dataLayer.push(push);

          window.location.href = "https://apuestas.codere.mx/es_MX";
        } else {
          status = "Failed";
          push = {
            event: "Login",
            playerMessage: response.playerMessage,
            errorCode: response.errorCode,
            status: status,
            playerCode: "",
            url: "/library/native/login/index.html",
            vipLevel: "",
            attemptedUser: username,
          };
          window.dataLayer.push(push);
          setMessage(response.playerMessage);
          setShowSpinner(false);
        }

        // console.log(push);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const usernameFocusHandler = (instance) => {
    // instance.target.scrollIntoView(false);
    // setMessage("");
    // console.log(instance);
  };
  const usernameBlurHandler = (instance) => {
    window.scrollTo(0, 0);
  };

  const focusHandler = (instance) => {
    instance.target.scrollIntoView(false);
    setMessage("");
    setShowEye(true);
  };
  const blurHandler = (target) => {
    setShowEye(true);
    setShowEyeSlash(false);
    window.scrollTo(0, 0);
  };

  const eyeClickHandler = () => {
    setShowEye(!showEye);
    setShowEyeSlash(!showEyeSlash);
  };

  const disableHandler = () => {
    if (showSpinner) return true;
    else return !(username && pw.length >= 3);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_LOGIN;
    script.async = true;
    script.onload = () => window.iapiSetDeliveryPlatform("Native");

    document.body.appendChild(script);
    const scriptPasskey = document.createElement("script");
    scriptPasskey.src =
      "https://login.codere.mx/jswrapper/fidoAuth.js?casino=codere.mx&min=1";
    scriptPasskey.async = true;
    scriptPasskey.onload = function () {
      const isFidoSupported = window.fidoAuth?.isSupported();
      const isHasPasskeys = window.localStorage.getItem("hasPasskeys");
      const authenticateCallback = async (response) => {
        // if (response.errorCode !== -11) {
        // instance.target.blur();
        // }
        if (response.errorCode === window.iapiERR_OK) {
          const pasResponse = await window.iapiLoginUsernameExternalToken(
            response.username,
            response.externalToken,
            1,
            "es"
          );
        }
      };
      if (isFidoSupported) {
        window.fidoAuth?.abortCurrent();
        if (isHasPasskeys === "true") {
          window.fidoAuth
            .authenticate(authenticateCallback)
            .then(() => {
              login();
            })
            .catch(() => {
              console.error("Could not initiate authenticate auth");
            });
        } else {
          window.fidoAuth
            .authenticateAutofillUI(authenticateCallback)
            .then(() => {
              login();
            })
            .catch(() => {
              console.error("Could not initiate authenticate auth");
            });
        }
      }
    };

    document.body.appendChild(scriptPasskey);
  }, []);

  const recapcha = async () => {
    // console.log("reCAPCHA: ");

    await captchaRef.current.executeAsync();

    const token = captchaRef.current.getValue();

    if (token) {
      let validToken = await verifyToken(token);

      setValidToken(validToken);

      if (validToken[0].success) {
        // console.log("validToken");
        setIsHuman(true);

        // console.log("validToken");
        login();
      } else {
        setShowSpinner(false);
      }
      // console.log(valid_token);
    }
  };

  const verifyToken = async (token) => {
    let APIResponse = [];

    try {
      let response = await axios.post(
        process.env.REACT_APP_DARKSITE,
        {
          reCAPTCHA_TOKEN: token,
        }
        // { headers: headers }
      );

      APIResponse.push(response["data"]);
      return APIResponse;
    } catch (error) {
      setShowSpinner(false);

      console.log(error);
    }
  };

  // terms and conditions popup
  const tycModalConfirm = () => {
    // console.log(loginResponse);
    window.iapiValidateTCVersion(
      loginResponse.sessionValidationData.SessionValidationByTCVersionData[0]
        .termVersionReference,
      1,
      1
    );
    login();
    setShowModal(false);
  };

  const onError = (msg) => {
    console.log("onError: ", msg);
  };
  const TermsAndConditionsPopup = () => {
    return (
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>Términos y condiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <div className="form-floating mb-3">
              <p>
                Para cumplir con los requerimientos gubernamentales cuando
                realices depósitos o cobros mayores a $35,285.25, deberás
                aceptar los términos del AVISO DE{" "}
                <a href="/privacidad">PRIVACIDAD</a> Y CONSTANCIA DE
                BENEFICIARIO CONTROLADOR.
              </p>
              <p>
                Para más información puedes consultar los{" "}
                <a href="/terminos-y-condiciones">términos y condiciones</a> en
                nuestra web.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={tycModalConfirm}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <div className={`Auth-form-container`}>
        <Form className="Auth-form" onSubmit={(e) => onSubmit(e)}>
          <div className="Auth-form-content">
            <Form.Group controlId="floatUsername">
              <FloatingLabel
                controlId="floatUsername"
                label="Usuario ó Correo Electrónico"
                className={`${username && "filled"}`}
              >
                <Form.Control
                  autoComplete="off"
                  onChange={(e) =>
                    setUserName(e.target.value.replace(alphanum_regex, ""))
                  }
                  onFocus={usernameFocusHandler}
                  onBlur={() => usernameBlurHandler}
                  type="text"
                  placeholder="a"
                  value={username}
                  maxLength={256}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="floatPw">
              <FloatingLabel
                controlId="floatPw"
                label="Contraseña"
                className={`${pw && "filled"}`}
              >
                <Form.Control
                  onChange={(e) => setPw(e.target.value)}
                  onFocus={focusHandler}
                  onBlur={(e) => blurHandler(e.target)}
                  type={
                    showEye ? "password" : showEyeSlash ? "text" : "password"
                  }
                  autoComplete="off"
                  placeholder="aa"
                  value={pw}
                  ref={pwRef}
                  maxLength={32}
                />

                {!showEye && !showEyeSlash ? null : showEye && pw.length > 0 ? (
                  <i
                    onClick={(e) => eyeClickHandler(e.target)}
                    className="pi pi-eye"
                  />
                ) : (
                  showEyeSlash && (
                    <i
                      onClick={(e) => eyeClickHandler(e.target)}
                      className="pi pi-eye-slash"
                    />
                  )
                )}
              </FloatingLabel>
            </Form.Group>

            <p className="mt-3 error">{message}</p>
            <Form.Check defaultChecked label="Recuérdame" />
          </div>
          <div className="d-grid gap-2 mt-3 buttons">
            {showSpinner ? (
              <Spinner />
            ) : (
              <Button
                // disabled={disableHandler()}
                type="submit"
                className="btn btn-primary iniciar"
              // onClick={(evt) => scriptLoaded(evt._reactName)}
              >
                Iniciar sesión
              </Button>
            )}
            <NavLink
              className="olvido"
              href="https://www.codere.mx/ayuda/contrasena-olvidada"
            >
              ¿Olvido su Contraseña o Usuario?
            </NavLink>
            <Button
              href={regis}
              type="button"
              className="btn btn-primary registro_v2"
            >
              Regístrate Ahora
            </Button>
            <div className="text-center lh-sm modal-terms">
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy" rel="nofollow">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms" rel="nofollow">
                Terms of Service
              </a>{" "}
              apply
            </div>
          </div>
        </Form>

        {TermsAndConditionsPopup()}
      </div>
      {/* <ReCAPTCHA
        ref={captchaRef}
        hl="es-419"
        onChange={recapcha}
        sitekey={process.env.REACT_APP_SITE_KEY}
        size="invisible"
        onErrored={onError}
      /> */}
    </>
  );
}
